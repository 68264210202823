import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import WrapperGoodbye from "../components/WrapperGoodbye"

const IndexPage = () => (
  <Layout>
    <SEO title="goobye" />
    <WrapperGoodbye />
  </Layout>
)

export default IndexPage
